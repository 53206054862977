<template>
  <div id="log">
    <div class="select is-rounded mr-2" :class="{'is-loading':loading}" v-if="adminMode">
      <select v-model="user" :class="{'has-text-success':parseInt(user.enable),'has-text-danger':!parseInt(user.enable)&&user.id}">
        <option value="" selected disabled>เลือกผู้ใช้งาน</option>
        <option v-for="(val,key) in users" :value="val" :key="key"
          :class="{'has-text-success':parseInt(val.enable),'has-text-danger':!parseInt(val.enable)}">
          {{val.name}} {{val.lname}} ({{val.email}})
        </option>
      </select>
    </div>
    <div class="select is-rounded mr-2" :class="{'is-loading':loading}" v-if="user.id">
      <select v-model="branch">
        <option value="" selected disabled>Select Plants</option>
        <option v-for="(val,key) in Branchs" :value="val" :key="key">{{val.name}}</option>
      </select>
    </div>
    <div class="select is-rounded mr-2" :class="{'is-loading':loading}" v-if="branch.id">
      <select v-model="node">
        <option value="" selected disabled>Select Node</option>
        <option v-for="(val,key) in Nodes" :value="val" :key="key">{{val.name}} ({{val.mac}})</option>
      </select>
    </div>
    <div class="select is-rounded mr-2" :class="{'is-loading':loading}" v-if="node.id">
      <select v-model="device" :class="{'has-text-success':parseInt(device.enable),'has-text-danger':!parseInt(device.enable)&&device.id}">
        <option value="">Data from all devices</option>
        <option v-for="(val,key) in Devices" :value="val" :key="key"
          :class="{'has-text-success':parseInt(val.enable),'has-text-danger':!parseInt(val.enable)}">
          Only Device Number {{val.number}}</option>
      </select>
    </div>
    <div style="float:right" v-if="node.id">
      <button type="button" class="button is-small is-primary" :class="{'is-loading':loading}" @click="get_report(true)">
        <span class="mr-1">Refresh</span>
        <i class="fas fa-retweet"></i>
      </button>
    </div>
    <hr>
    <div v-if="loading && !ReportLog.length">
      <button type="button" class="button is-fullwidth is-large is-loading"></button>
    </div>
    <div style="max-height:650px;overflow-y:auto;" v-else>
      <table class="table is-bordered" v-if="ReportLog.length">
        <thead>
          <tr class="has-text-centered is-size-7">
            <th>Node</th>
            <th>Device No.</th>
            <th>Phase</th>
            <th>Volt</th>
            <th>Current</th>
            <th>Power</th>
            <th>Power Factor</th>
            <th>Frequency</th>
            <th>Imp Energy</th>
            <th>Exp Energy</th>
            <th>Date Time</th>
          </tr>
        </thead>
        <tbody class="has-text-centered is-size-7" v-for="(val,key) in ReportLog" :key="key">
          <tr>
            <td rowspan="4"> <b>{{node.name}}</b> <br><br> <b>Mac:</b> {{node.mac}} </td>
            <td class="cursor_pointer" @click="redirect('/member/device/'+val.id)"
            :class="color_enable(val)" rowspan="4">{{val.number}} {{r_enable(val)}}</td>
            <td class="has-text-info"> <b>A</b> </td>
            <td>{{val.Volt_a}} V</td>
            <td>{{val.Current_a}} A</td>
            <td>{{val.Power_a}} W</td>
            <td>{{val.PF_a}} </td>
            <td>{{val.Freq}} Hz</td>
            <td rowspan="4">{{val.Imp_Energy}} Kwh</td>
            <td rowspan="4">{{val.Exp_Energy}} Kwh</td>
            <td rowspan="4">{{val.created | date_data}}</td>
          </tr>
          <tr>
            <td class="has-text-info"> <b>B</b> </td>
            <td>{{val.Volt_b}} V</td>
            <td>{{val.Current_b}} A</td>
            <td>{{val.Power_b}} W</td>
            <td>{{val.PF_b}} </td>
            <td>{{val.Freq}} Hz</td>
          </tr>
          <tr>
            <td class="has-text-info"> <b>C</b> </td>
            <td>{{val.Volt_c}} V</td>
            <td>{{val.Current_c}} A</td>
            <td>{{val.Power_c}} W</td>
            <td>{{val.PF_c}} </td>
            <td>{{val.Freq}} Hz</td>
          </tr>
          <tr>
            <td class="has-text-info"> <b>Total</b> </td>
            <td colspan="2"></td>
            <td>{{val.Power_t}} W</td>
            <td>{{val.PF_t}} </td>
            <td>{{val.Freq}} Hz</td>
          </tr>
          <tr>
            <td colspan="11">......</td>
          </tr>
        </tbody>
      </table>
      <div v-observe-visibility="handleScrollToBottom" v-if="!loading && ReportLog.length>=50"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Log',
  props: {
    adminMode: {
      type: Boolean,
      dafault: false,
    },
    userData: Object,
    loaded: Object,
    users: Array,
    branchs: Array,
    nodes: Array,
    devices: Array,
  },
  data(){
    return {
      user: "",
      branch: "",
      node: "",
      device: "",
      loading: false,
      report_log: [],
      full_node_ID: [],
      full_device_ID: [],
      countScrollToBottom: 0,
    }
  },
  created(){
    if(!this.adminMode){
      this.user = this.userData;
    }
  },
  methods:{
    get_report(refresh=false){
      if(!refresh){
        let check_full_data = this.check_full_data();
        if(check_full_data) return false;
      }
      // ............................................
      if(this.loading || !this.node.id) return false;

      this.loading = true;
      let ignore = this.report_log.map(log => log.id);
      let url = this.api+'user/get_report_log_report/'+this.secret;
      let data = new FormData();
      data.set("n_id", this.node.id);
      if(this.device.id){
        data.set("d_id", this.device.id);
      }
      if(ignore.length){
        data.set('ignore',JSON.stringify(ignore) );
      }
      data.set("secret", this.secret);
      this.Axios.post(url, data)
      .then((response)=>{
        console.log(response.data);
        if(response.data.error==0){
          if(!this.report_log.length){
            this.report_log = response.data.data;
          }else{
             response.data.data.forEach((e) => {
               const LOG = this.report_log.map((log) => parseInt(log.id)).indexOf(parseInt(e.id));
               if(LOG==-1)this.report_log.push(e);
            });
          }
        }else{
          if(this.device.id){
            const INDEX = this.full_device_ID.map((d_id) => parseInt(d_id)).indexOf(parseInt(this.device.id));
            if(INDEX==-1) this.full_device_ID.push(this.device.id);
          }else{
            const INDEX = this.full_node_ID.map((n_id) => parseInt(n_id)).indexOf(parseInt(this.node.id));
            if(INDEX==-1) this.full_node_ID.push(this.node.id);
          }
        }
      })
      .catch((error)=>{
        console.error(error);
        this.$swal("","get_report_log_report()","warning");
      })
      .then(()=>{
        this.countScrollToBottom = 0;
        setTimeout(() => this.loading=false, 1000);
      })
    },
    check_full_data(){
      let INDEX = null;
      if(this.device.id){
        INDEX = this.full_device_ID.map((d_id) => parseInt(d_id)).indexOf(parseInt(this.device.id));
        // console.log("full_device_ID",this.device.id,INDEX)
      }else{
        INDEX = this.full_node_ID.map((n_id) => parseInt(n_id)).indexOf(parseInt(this.node.id));
        // console.log("full_node_ID",this.node.id,INDEX)
      }
      if(INDEX!==-1){
        this.countScrollToBottom = 0;
        return true; // return true if full data
      }
    },
    color_enable(val){
      return parseInt(val.enable) ? "has-text-success" : "has-text-danger";
    },
    r_enable(val){
      return parseInt(val.enable) ? "(Enable)" : "(Disable)";
    },
    handleScrollToBottom() {
      // console.log("countScrollToBottom",this.countScrollToBottom);
      if(this.countScrollToBottom>=1){
        // Any code to be executed when the window is scrolled
        console.log('calling handleScrollToBottom');
        this.get_report();
      }
      this.countScrollToBottom++;
    },
  },
  computed:{
    Branchs(){
      return this.branchs.filter(b => { return parseInt(b.u_id)==parseInt(this.user.id) });
    },
    Nodes(){
      return this.nodes.filter(n => { return parseInt(n.b_id)==parseInt(this.branch.id) });
    },
    Devices(){
      return this.devices.filter(d => { return parseInt(d.n_id)==parseInt(this.node.id) });
    },
    ReportLog(){
      let reportLog = this.report_log;
      if(this.device.id){
        reportLog = this.report_log.filter(log => { return parseInt(log.d_id)==parseInt(this.device.id) });
      }else{
        reportLog = this.report_log.filter(log => { return parseInt(log.n_id)==parseInt(this.node.id) });
      }
      if(reportLog.length){
        reportLog = reportLog.sort((a, b) => { if(parseInt(a.id)>parseInt(b.id)) return -1 })
      }
      return reportLog;
    }
  },
  watch:{
    user(val){
      if(this.adminMode){
        this.branch = "";
        if(!this.loaded.get_branch){
          this.$emit('get_branch',val.id);
        }else{
          const INDEX_ = this.loaded.get_branch.map((b_id) => parseInt(b_id)).indexOf(parseInt(val.id));
          if(INDEX_==-1){
            this.$emit('get_branch',val.id);
          }
        }
      }
    },
    branch(val){
      this.node = "";
      if(!this.loaded.get_node){
        this.$emit('get_node',val.id);
      }else{
        const INDEX_ = this.loaded.get_node.map((n_id) => parseInt(n_id)).indexOf(parseInt(val.id));
        if(INDEX_==-1){
          this.$emit('get_node',val.id);
        }
      }
      this.get_report();
    },
    node(val){
      this.device = "";
      if(!this.loaded.get_device){
        this.$emit('get_device',val.id);
      }else{
        const INDEX_ = this.loaded.get_device.map((n_id) => parseInt(n_id)).indexOf(parseInt(val.id));
        if(INDEX_==-1){
          this.$emit('get_device',val.id);
        }
      }
      this.get_report();
    },
    device(val){
      if(val.id)this.get_report();
    }
  }
}
</script>
